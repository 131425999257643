/* eslint-disable no-console */
import { Logger } from "@nx/abstract-classes"
import { envUtils } from "@nx/utils"

class UiLogger implements Logger {
  log(prefix: string, ...msgs: any[]) {
    if (shouldSupressLog()) return

    console.log(...this.formatMsgs(prefix, msgs))
  }

  productionLog(prefix: string, ...msgs: any[]) {
    console.log(...this.formatMsgs(prefix, msgs))
  }

  info(prefix: string, ...msgs: any[]) {
    if (shouldSupressLog()) return

    console.info(...this.formatMsgs(prefix, msgs))
  }

  warn(prefix: string, ...msgs: any[]) {
    if (shouldSupressLog()) return

    console.warn(...this.formatMsgs(prefix, msgs))
  }

  error(prefix: string, ...msgs: any[]) {
    if (shouldSupressLog()) return

    console.error(...this.formatMsgs(prefix, msgs))
  }

  errorEnriched(prefix: string, _error: any, ...msgs: any[]) {
    if (shouldSupressLog()) return

    this.error(
      prefix,
      ...[
        ...msgs.map((m) => JSON.stringify(m, null, 2)),
        "\n",
        _error.response?.data || _error,
        "\n",
        _error.stack,
      ].filter((x) => x !== undefined),
    )
  }

  private formatMsgs(prefix: string, msgs: any[]) {
    return [`%c${prefix}`, "background: #222; color: #bada55", ...msgs]
  }
}

const uiLogger = new UiLogger()

export default uiLogger

export { shouldSupressLog }

function shouldSupressLog() {
  return !envUtils.isDev
}
