import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import {
  IToolRecord,
  IToolStep,
  IToolRecordUserStep,
  IToolAnswer,
  IUserIssue,
} from "@nx/api-interfaces"

import { ICurrentTool } from "../../types/tool"
import { IRootState } from "../root-reducer"

const initialState = {
  isLoading: false,
  currentStepNumber: {
    history: [] as number[],
    current: 0,
  },
  answerByStep: {} as Record<string, string>,
  vars: {} as Record<string, string>,
  urlVars: {} as Record<string, any>,
  tool: null as unknown as ICurrentTool,
  toolRecordId: "",
  toolRecordUserSteps: [] as unknown as IToolRecordUserStep[],
}

export const CurrentToolSlice = createSlice({
  name: "currentTool",
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    createToolRecord: (state, action) => {},

    createToolRecordSuccess: (state, action: PayloadAction<IToolRecord>) => {
      state.toolRecordId = action.payload._id
      state.toolRecordUserSteps = action.payload.userSteps
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    createToolRecordError: (state) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCurrentTool: (state, action: PayloadAction<string>) => {
      state.isLoading = true
    },

    getCurrentToolSuccess: (state, action: PayloadAction<ICurrentTool>) => {
      state.isLoading = false
      state.tool = action.payload
    },

    getCurrentToolError: (state) => {
      state.isLoading = false
    },

    syncStateFromUrl: (state, action: PayloadAction<Record<string, any>>) => {
      const { startingStepNumber, ...vars } = action.payload
      state.urlVars = vars

      if (Number.isInteger(startingStepNumber)) {
        state.currentStepNumber.current = startingStepNumber - 1
      }
    },

    stepBack: (state) => {
      const previousStepNumber = state.currentStepNumber.history.pop() as number
      state.currentStepNumber.current = previousStepNumber
      state.toolRecordUserSteps.push({
        stepIdx: previousStepNumber,
        incomingAction: "backBtn",
      })
    },

    // Used adhoc by input and textarea steps as well
    submitAnswer: (state, action: PayloadAction<IToolAnswer>) => {
      const answer = action.payload
      applyBaseAnswer(state, answer)

      addToolRecordUserStep(state, answer)

      // handled by middleware
      if (answer.goToExternalLink || answer.goToLink) {
        return
      }
      if (shouldApplyGoToLogic(state, answer)) {
        applyGoToLogic(state, answer)
      } else {
        goToStep(state, state.currentStepNumber.current + 1)
      }
    },

    submitStars: (state, action: PayloadAction<number>) => {
      const stars = action.payload
      state.answerByStep[state.currentStepNumber.current] = stars as any
      const step = state.tool.steps[state.currentStepNumber.current]
      if (step.varKey) {
        state.vars[step.varKey] = stars as any
      }
      goToStep(state, state.currentStepNumber.current + 1)

      const adHocHackyAnswer = {
        text: stars,
      } as any

      addToolRecordUserStep(state, adHocHackyAnswer)
    },

    submitUserIssue: (state, action: PayloadAction<IUserIssue[]>) => {
      const stringifiedIssues = JSON.stringify(action.payload)
      state.answerByStep[state.currentStepNumber.current] = stringifiedIssues
      const step = state.tool.steps[state.currentStepNumber.current]
      if (step.varKey) {
        state.vars[step.varKey] = stringifiedIssues
      }
      goToStep(state, state.currentStepNumber.current + 1)

      const adHocHackyAnswer = {
        text: stringifiedIssues,
      } as any

      addToolRecordUserStep(state, adHocHackyAnswer)
    },
  },
})

export const {
  getCurrentTool,
  getCurrentToolSuccess,
  getCurrentToolError,
  syncStateFromUrl,
  stepBack,
  submitAnswer,
  submitStars,
  submitUserIssue,
  createToolRecord,
  createToolRecordSuccess,
  createToolRecordError,
} = CurrentToolSlice.actions

export const selectCurrentTool = (state: IRootState): IState =>
  state.currentTool

export const selectCurrentStep = (state: IRootState): IToolStep =>
  state.currentTool.tool.steps[state.currentTool.currentStepNumber.current]

export const selectAllVars = (state: IRootState): Record<string, any> => {
  return Object.assign(
    {},
    { user: state.currentUser.user },
    state.currentTool.vars,
    state.currentTool.urlVars,
  )
}

const currentToolReducer = CurrentToolSlice.reducer

export default currentToolReducer

/*
 * Helpers
 */
function applyBaseAnswer(state: IState, answer: IToolAnswer) {
  const step = state.tool.steps[state.currentStepNumber.current]
  state.answerByStep[state.currentStepNumber.current] = answer.text
  if (step.varKey) {
    state.vars[step.varKey] = answer.text
  }
}

function addToolRecordUserStep(state: IState, answer: IToolAnswer) {
  state.toolRecordUserSteps.push({
    stepIdx: state.currentStepNumber.current,
    incomingAction: "answer",
    incomingAnswerText: answer.text,
  })
}

function shouldApplyGoToLogic(state: IState, answer: IToolAnswer) {
  const step = state.tool.steps[state.currentStepNumber.current]
  const baseGoTo = answer.type === "input" ? step : answer

  return !!baseGoTo.goToStepByKey || !!baseGoTo.goToStepByNumber
}

function applyGoToLogic(state: IState, answer: IToolAnswer) {
  const step = state.tool.steps[state.currentStepNumber.current]
  const baseGoTo = answer.type === "input" ? step : answer

  if (baseGoTo.goToStepByNumber) {
    goToStep(state, Number(baseGoTo.goToStepByNumber) - 1)
    return
  }

  if (baseGoTo.goToStepByKey) {
    const nextStepNumber = state.tool.steps.findIndex(
      (step) => step.key === baseGoTo.goToStepByKey,
    )
    if (nextStepNumber === -1) {
      throw new Error("Step not found for key: " + baseGoTo.goToStepByKey)
    }
    goToStep(state, nextStepNumber)
    return
  }
}

const goToStep = (state: IState, stepNumber: number) => {
  state.currentStepNumber.history.push(state.currentStepNumber.current)
  state.currentStepNumber.current = stepNumber
}

type IState = typeof initialState
