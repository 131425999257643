import { Provider } from "react-redux"

import { IUser } from "@nx/api-interfaces"

import Root from "../components/Root/Root"

import store from "./store"

import "../styles/index.scss"

export default function ToolViewer({ toolKey, user }: IProps) {
  return (
    <Provider store={store}>
      <Root toolKey={toolKey} user={user} />
    </Provider>
  )
}

interface IProps {
  toolKey: string
  user: IUser
}
