import { Logger } from "../abstract-classes"

abstract class Base {
  static unprintableVariables: any[] = []

  static makeInit(...args: any[]) {
    return (..._args: any[]) => {
      // @ts-ignore
      const instance = new this(...args, ..._args)
      return instance.init()
    }
  }

  async init() {
    if (typeof this._init !== "function") {
      throw new Error("_init not implemented")
    }

    try {
      return await this._init()
    } catch (err) {
      return this.catch(err)
    }
  }

  _init?(): any | Promise<any>

  catch(error: Error | any) {
    this.logEnrichedError(error)
  }

  get prettyClassName(): string {
    const name = this.constructor.name
    return (
      name[0] +
      name
        .slice(1)
        .split("")
        .map((c) => (c.match(/[A-Z]/) ? ` ${c.toLowerCase()}` : c))
        .join("")
    )
  }

  get printableVariables(): object {
    return this.toH(
      Object.entries(this).filter(
        ([key, val]) =>
          // @ts-ignore
          !this.constructor.unprintableVariables.includes(key) &&
          typeof val !== "function",
      ),
    )
  }

  get variables(): object {
    return this.toH(Object.entries(this))
  }

  logEnrichedError(error: Error | any, ...msgs: any[]) {
    this.logger.errorEnriched(
      this.logPrefix,
      error,
      ...msgs,
      error.stack,
      "Printable instance variables:",
      JSON.stringify(this.printableVariables),
    )
  }

  log(...msgs: any[]) {
    this.logger.log(this.logPrefix, ...msgs)
  }

  shout(...msgs: any[]) {
    const sep = "***************"
    /* eslint-disable no-console */
    console.log(sep + this.logPrefix + sep)
    console.log(this.logPrefix, ...msgs)
    console.log(sep + this.logPrefix + sep)
    /* eslint-enable no-console */
  }

  error(...msgs: any[]) {
    this.logger.error(this.logPrefix, ...msgs)
  }

  info(...msgs: any[]) {
    this.logger.info(this.logPrefix, ...msgs)
  }

  warn(...msgs: any[]) {
    this.logger.warn(this.logPrefix, ...msgs)
  }

  get logPrefix(): string {
    return `[${this.constructor.name}]`
  }

  get logger(): Logger {
    return (globalThis as any).logger as Logger
  }

  private toH = (arr: any[]) =>
    arr.reduce(
      (acc: object, [key, val]: [string, any]) => ({
        ...acc,
        [key]: val,
      }),
      {},
    )
}

export default Base
