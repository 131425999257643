import _ from "lodash"
import { PayloadAction } from "@reduxjs/toolkit"
import { all, put, select, takeLatest } from "redux-saga/effects"

import { ITool } from "@nx/api-interfaces"
import { api, uiLogger } from "@nx/ui-utils"

import {
  getCurrentTool,
  getCurrentToolError,
  getCurrentToolSuccess,
  createToolRecord,
  createToolRecordSuccess,
  createToolRecordError,
  submitAnswer,
  selectCurrentTool,
  submitUserIssue,
  submitStars,
  stepBack,
} from "./current-tool.slice"

export default function* currentToolSaga() {
  yield all([
    takeLatest(getCurrentTool, getCurrentToolSaga),
    takeLatest(createToolRecord, createToolRecordSaga),
    takeLatest(submitAnswer, addUserStepToToolRecordSaga),
    takeLatest(submitStars, addUserStepToToolRecordSaga),
    takeLatest(submitUserIssue, addUserStepToToolRecordSaga),
    takeLatest(stepBack, addUserStepToToolRecordSaga),
  ])
}

function* getCurrentToolSaga(action: PayloadAction<string>) {
  const key = action.payload
  try {
    const { data: tool } = yield api.getTool(key)
    yield put(getCurrentToolSuccess(tool))
    yield put(createToolRecord(tool))
  } catch (error: any) {
    uiLogger.error("[getCurrentToolSaga]", key, error)
    yield put(getCurrentToolError())
  }
}

function* createToolRecordSaga(action: PayloadAction<ITool>) {
  const tool = action.payload
  try {
    const { data: toolRecord } = yield api.createToolRecord({
      ...tool,
      userSteps: [
        {
          stepIdx: 0,
          incomingAction: "start",
        },
      ],
    })
    yield put(createToolRecordSuccess(toolRecord))
  } catch (error: any) {
    uiLogger.error("[createToolRecordSaga]", tool)
    yield put(createToolRecordError())
  }
}

function* addUserStepToToolRecordSaga() {
  const { toolRecordId, toolRecordUserSteps } = yield select(selectCurrentTool)
  yield api.addToolRecordUserStep(
    toolRecordId,
    _.last(toolRecordUserSteps) as any,
  )
}
