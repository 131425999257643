export * from "./tool/tool"
export * from "./tool-record/tool-record"
export * from "./user/user"
export * from "./api/api"

export const GOOGLE_AUTH_CLIENT_ID =
  "765313664019-1klevr08kv52gm098rc1slju6t9om05g.apps.googleusercontent.com"

export const SSO_PROVIDERS = ["facebook", "google"] as const

export const MAIN_SITE_BASE_URL =
  // @ts-ignore
  process.env.NODE_ENV === "development"
    ? "http://localhost:3010"
    : "https://breathein.net"

export const MAIN_SITE_TOOLS_BASE_URL = MAIN_SITE_BASE_URL + "/tools"

export const ADMIN_SITE_BASE_URL =
  // @ts-ignore
  process.env.NODE_ENV === "development"
    ? "http://localhost:3011"
    : "https://admin.breathein.net"

export const ADMIN_SITE_TOOLS_BASE_URL = ADMIN_SITE_BASE_URL + "/tools"
