import { combineReducers } from "@reduxjs/toolkit"

import currentUserReducer from "./current-user/current-user.slice"
import toolsReducer from "./tools/tools.slice"

const rootReducer = combineReducers({
  tools: toolsReducer,
  currentUser: currentUserReducer,
})

export default rootReducer

export type IRootState = ReturnType<typeof rootReducer>
