import Routes from "./routes/Routes"
import { useAppSelector } from "./hooks/store"
import { selectCurrentUser } from "./store/current-user/current-user.slice"
import "./App.css"
import AuthScreen from "./components/AuthScreen/AuthScreen"

function App() {
  const { isLoading, user } = useAppSelector(selectCurrentUser)

  if (isLoading) {
    return <h1>Loading user</h1>
  }

  if (!user) {
    return <AuthScreen />
  }

  return (
    <div>
      <Routes />
    </div>
  )
}

export default App
