import { useParams } from "react-router"

import ToolViewer from "@nx/tool-viewer"

import TopNav from "../../components/TopNav/TopNav"
import { useAppSelector } from "../../hooks/store"
import { selectCurrentUser } from "../../store/current-user/current-user.slice"

import "./Tool.css"

export default function Tool() {
  const { toolKey } = useParams()
  const { user } = useAppSelector(selectCurrentUser)

  return (
    <div className="tool-viewer-host">
      <TopNav />
      <ToolViewer toolKey={toolKey as string} user={user} />
    </div>
  )
}
