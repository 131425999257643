import { Base } from "@nx/abstract-classes"

class NumberUtils extends Base {
  /**
   * @examples
   * 5110._prettify() -> 5.1k
   *
   * 5110._prettify(2) -> 5.10k
   *
   * 1000000._prettify() -> 1m
   */
  prettify(n: number, { digits = 1 } = {}) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ]
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    const item = lookup
      .slice()
      .reverse()
      .find((_item) => n >= _item.value)

    return item
      ? (n / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0"
  }
}

const numberUtils = new NumberUtils()

export default numberUtils
