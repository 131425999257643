import { Button } from "react-bootstrap"

import { useAppDispatch, useAppSelector } from "../../hooks/store"
import {
  logout,
  selectCurrentUser,
} from "../../store/current-user/current-user.slice"

import "./TopNav.css"

export default function TopNav() {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(selectCurrentUser)

  const _logout = () => dispatch(logout())

  return (
    <div className="top-nav">
      <div className="top-nav-left"></div>
      <div className="top-nav-right">
        {user?.isAdmin && (
          <Button variant="link">
            <a href="/tools">Tools</a>
          </Button>
        )}
        <Button variant="link" onClick={_logout}>
          Logout
        </Button>
      </div>
    </div>
  )
}
