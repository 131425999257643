import { BrowserRouter, Routes as RouterRoutes, Route } from "react-router-dom"

import Home from "./Home/Home"
import Tool from "./Tool/Tool"
import ToolList from "./ToolList/ToolList"

export default function Routes() {
  return (
    <BrowserRouter>
      <RouterRoutes>
        <Route path="/">
          <Route path="/" element={<Home />} />
          <Route path="tools" element={<ToolList />} />
          <Route path="tools/:toolKey" element={<Tool />} />
        </Route>
      </RouterRoutes>
    </BrowserRouter>
  )
}
