import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IUser } from "@nx/api-interfaces"

import { IRootState } from "../root-reducer"

export const currentUserSlice = createSlice({
  name: "currentUser",
  initialState: {
    user: null as unknown as IUser,
  },
  reducers: {
    setCurrentUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload
    },
  },
})

export const { setCurrentUser } = currentUserSlice.actions

export const selectCurrentUser = (state: IRootState) => state.currentUser

const currentUserReducer = currentUserSlice.reducer

export default currentUserReducer
