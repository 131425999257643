import _ from "lodash"

import { Parsed } from "@nx/components"

import { useAppSelector } from "../../hooks/store"
import {
  selectAllVars,
  selectCurrentTool,
} from "../../store/current-tool/current-tool.slice"

import "./ToolViewerParsed.css"

export default function ToolViewerParsed({ children }: IProps) {
  const { answerByStep, currentStepNumber } = useAppSelector(selectCurrentTool)

  const allVars = useAppSelector(selectAllVars)

  const echoOutput = answerByStep[_.last(currentStepNumber.history) as number]

  return (
    <Parsed vars={allVars} echoOutput={echoOutput}>
      {children}
    </Parsed>
  )
}

interface IProps {
  children?: string
}
