import { Liquid } from "liquidjs"
import { useEffect, useState } from "react"

import { Html } from "../components"

import "./Parsed.css"

const engine = new Liquid()

export default function Parsed({ children, vars, echoOutput }: IProps) {
  const [output, setOutput] = useState("")

  useEffect(() => {
    if (!children) return

    const _render = async () => {
      const parsed = parse(children, echoOutput)

      const tpl = engine.parse(parsed)
      const _output = await engine.render(tpl, { ...vars, block: { ...vars } })
      setOutput(_output)
    }

    _render()
  }, [children, echoOutput, vars])

  return <Html>{output}</Html>
}

interface IProps {
  children?: string
  vars: Record<string, any>
  echoOutput: string
}

function parse(text: string, echoOutput: string): string {
  return text
    .replace(/\$\{echo}/g, () => {
      return `<span data-hook="user-input-reflection-echo" class="_components-user-block-input-reflection">"${echoOutput}"</span>`
    })
    .replace(/{{ (block.*)}}/g, (x) => {
      return `<span data-hook="user-input-reflection-block" class="_components-user-block-input-reflection">"${x}"</span>`
    })
    .replace(/{{ (?!(block))(.*)}}/g, (x) => {
      return `<em data-hook="user-input-reflection-inline">${x}</em>`
    })
}
