import { combineReducers } from "@reduxjs/toolkit"

import currentToolReducer from "./current-tool/current-tool.slice"
import currentUserReducer from "./current-user/current-user.slice"

const rootReducer = combineReducers({
  currentTool: currentToolReducer,
  currentUser: currentUserReducer,
})

export default rootReducer

export type IRootState = ReturnType<typeof rootReducer>
