import * as qs from "query-string"
import { Base } from "@nx/abstract-classes"

class UrlUtils extends Base {
  parse(queryString = window.location.search) {
    const parsed = qs.parse(queryString, {
      arrayFormat: "bracket",
      parseBooleans: true,
      parseNumbers: true,
    })

    return Object.entries(parsed).reduce((acc, [key, value]) => {
      if (!this.isParsedObjectKey(key)) {
        return Object.assign(acc, { [key]: value })
      }

      const parsedChildKey = this.parseChildKey(key)
      const parsedMasterKey = key.match(/\w+/)![0]
      acc[parsedMasterKey] = acc[parsedMasterKey] || {}
      acc[parsedMasterKey][parsedChildKey] = value

      return acc
    }, {} as Record<string, any>)
  }

  private isParsedObjectKey(key: string) {
    return !!key.match(/\[\w+\]/)
  }

  private parseChildKey(key: string) {
    // @ts-ignore
    const matches = key.matchAll(/\[(\w+)\]/g)
    for (const match of matches) {
      return match[1] || ""
    }

    return ""
  }
}

const urUtils = new UrlUtils()

export default urUtils
