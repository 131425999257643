import { Base } from "@nx/abstract-classes"

class Cookies extends Base {
  doesSessionExist() {
    return document.cookie.includes("breath_doesSessionExist")
  }
}

const cookies = new Cookies()

export default cookies
