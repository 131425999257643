import { PayloadAction } from "@reduxjs/toolkit"

import { analytics } from "@nx/ui-utils"
import { IToolAnswer } from "@nx/api-interfaces"

import { stepBack, submitAnswer } from "../current-tool/current-tool.slice"

const analyticsMiddleware =
  (store: any) => (next: any) => (action: PayloadAction<any>) => {
    if (action.type === stepBack.type) {
      onStepBack(store.getState())
    } else if ([submitAnswer.type].includes(action.type)) {
      onSubmitAnswer(store.getState(), action.payload)
    }

    next(action)
  }

export default analyticsMiddleware

/*
 * Helpers
 */
function onSubmitAnswer(state: any, answer: IToolAnswer) {
  analytics.answerToolStep({
    toolKey: state.currentTool.tool.key,
    stepIdx: state.currentTool.currentStepNumber.current,
    answerType: answer.type,
    answerText: answer.text,
    goToStepByKey: answer.goToStepByKey,
    goToStepByNumber: answer.goToStepByNumber,
  })
}

function onStepBack(state: any) {
  analytics.goBackToolStep({
    toolKey: state.currentTool.tool.key,
    stepIdx: state.currentTool.currentStepNumber.current,
  })
}
