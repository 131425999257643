import { configureStore } from "@reduxjs/toolkit"

import { loggerMidl } from "@nx/ui-utils"

import sagaMiddleware, { initSagaMiddleware } from "./middlewares/saga"
import rootReducer from "./root-reducer"
import analyticsMiddleware from "./middlewares/analytics"
import toolsMiddleware from "./middlewares/tool"

const store = getStore()

export default function initStore() {
  initSagaMiddleware()

  return store
}

/*
 * Utils
 */

// For unit tests
function getStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        sagaMiddleware,
        analyticsMiddleware,
        toolsMiddleware,
        loggerMidl,
      ), // logger last
  })
}

export type IAppDispatch = typeof store.dispatch
export type IStore = typeof store
