import { useAppSelector } from "../../hooks/store"
import { selectCurrentTool } from "../../store/current-tool/current-tool.slice"

export default function Ltr({ ltr, rtl }: IProps) {
  const { tool } = useAppSelector(selectCurrentTool)

  return <span>{tool?.isRtl ? rtl : ltr}</span>
}

interface IProps {
  ltr: string
  rtl: string
}
