import uniq from "lodash/uniq"
import { Base } from "@nx/abstract-classes"
import { ITool } from "@nx/api-interfaces"

class ToolUtils extends Base {
  isKeyValid = (key: string) => !!key.match(/^[a-z-_\d]+[a-z\d]$/)

  getVarKeys = (tool?: ITool) =>
    uniq(tool?.steps.map((s) => s.varKey).filter(Boolean)) || []
}

const toolUtils = new ToolUtils()

export default toolUtils
