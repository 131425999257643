import {
  CredentialResponse,
  GoogleLogin,
  GoogleOAuthProvider,
} from "@react-oauth/google"

import { analytics } from "@nx/ui-utils"
import { GOOGLE_AUTH_CLIENT_ID } from "@nx/constants"

import "./GoogleAuthBtn.module.scss"

export default function GoogleAuthBtn({ onSuccess, supressAnalytics }: IProps) {
  const onError = () => {
    alert("Login Failed")

    if (!supressAnalytics) {
      analytics.userAuthFail("google")
    }
  }

  const onMaybeSuccess = (credentialResponse: CredentialResponse) => {
    const token = credentialResponse?.credential
    if (!token) {
      onError()
      return
    }

    onSuccess(token)

    if (!supressAnalytics) {
      analytics.userAuthSuccess("google")
    }
  }

  return (
    <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
      <GoogleLogin
        useOneTap={false}
        onSuccess={onMaybeSuccess}
        onError={onError}
      />
    </GoogleOAuthProvider>
  )
}

interface IProps {
  onSuccess: (token: string) => void
  supressAnalytics?: boolean
}
