import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { ISsoProvider, IUser } from "@nx/api-interfaces"

import { IRootState } from "../root-reducer"

export const currentUserSlice = createSlice({
  name: "currentUser",
  initialState: {
    isLoading: false,
    isProcessing: false,
    user: null as unknown as IUser,
  },
  reducers: {
    fetchCurrentUser: (state) => {
      state.isLoading = true
    },

    fetchCurrentUserSuccess: (state, action: PayloadAction<IUser>) => {
      state.isLoading = false
      state.user = action.payload
    },

    fetchCurrentUserError: (state) => {
      state.isLoading = false
    },

    sso: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ token: string; provider: ISsoProvider }>,
    ) => {
      state.isProcessing = true
    },

    ssoSuccess: (state, action: PayloadAction<IUser>) => {
      state.isProcessing = false
      state.user = action.payload
    },

    ssoError: (state) => {
      state.isProcessing = false
    },

    logout: (state) => {
      state.isProcessing = true
    },

    logoutSuccess: (state) => {
      state.isProcessing = false
      state.user = null as unknown as IUser
    },

    logoutError: (state) => {
      state.isProcessing = false
    },
  },
})

export const {
  fetchCurrentUser,
  fetchCurrentUserSuccess,
  fetchCurrentUserError,
  sso,
  ssoSuccess,
  ssoError,
  logout,
  logoutSuccess,
  logoutError,
} = currentUserSlice.actions

export const selectCurrentUser = (state: IRootState) => state.currentUser

const currentUserReducer = currentUserSlice.reducer

export default currentUserReducer
