import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { ITool } from "@nx/api-interfaces"

import { IRootState } from "../root-reducer"

export const toolsSlice = createSlice({
  name: "tools",
  initialState: {
    isLoading: false,
    isProcessing: false,
    tools: [] as ITool[],
  },
  reducers: {
    listTools: (state) => {
      state.isLoading = true
    },

    listToolsSuccess: (state, action: PayloadAction<ITool[]>) => {
      state.isLoading = false
      state.tools = action.payload
    },

    listToolsError: (state) => {
      state.isLoading = false
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createTool: (state, action) => {
      state.isProcessing = true
    },

    createToolSuccess: (state, action: PayloadAction<ITool>) => {
      state.isProcessing = false
      state.tools.push(action.payload)
    },

    createToolError: (state) => {
      state.isProcessing = false
    },
  },
})

export const {
  listTools,
  listToolsSuccess,
  listToolsError,
  createTool,
  createToolSuccess,
  createToolError,
} = toolsSlice.actions

export const selectTools = (state: IRootState) => state.tools

const toolsReducer = toolsSlice.reducer

export default toolsReducer
