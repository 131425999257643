import { StrictMode } from "react"
import * as ReactDOM from "react-dom/client"
import { Provider } from "react-redux"

import { cookies, uiLogger } from "@nx/ui-utils"

import initStore from "./store/init-store"
import { fetchCurrentUser } from "./store/current-user/current-user.slice"
import App from "./App"

// @ts-ignore
window.logger = uiLogger

const store = initStore()

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
)

fetchUserIfNeeded()

function fetchUserIfNeeded() {
  if (cookies.doesSessionExist()) {
    store.dispatch(fetchCurrentUser())
  }
}
