import snakecaseKeys from "snakecase-keys"
import { Base } from "@nx/abstract-classes"
import { IPaymentMethodType } from "@nx/api-interfaces"

class Analytics extends Base {
  mainPurchaseCheckoutButtonClicked(paymentMethodType: IPaymentMethodType) {
    this.event("main_purchase_checkout_button_clicked", {
      paymentMethodType,
    })
  }

  mainPurchaseSuccess() {
    this.event("main_purchase_success")
  }

  mainPurchaseError() {
    this.event("main_purchase_error")
  }

  userAuthSuccess(provider: IAuthProviderName) {
    this.event("user_auth_success", {
      provider,
    })
  }

  userAuthFail(provider: IAuthProviderName) {
    this.event("user_auth_fail", {
      provider,
    })
  }

  goToSection(screen: IScreenName, fromIdx: number, toIdx: number) {
    this.event("home_go_to_section", {
      screen,
      from: fromIdx,
      to: toIdx,
    })
  }

  answerToolStep({
    toolKey,
    stepIdx,
    answerType,
    answerText,
    goToStepByKey,
    goToStepByNumber,
  }: IAnswerToolStepArgs) {
    this.event("answer_tool_step", {
      toolKey,
      stepIdx,
      answerType,
      answerText,
      goToStepByKey,
      goToStepByNumber,
    })
  }

  goBackToolStep({ toolKey, stepIdx }: IGoBackToolStepArgs) {
    this.event("go_back_tool_step", {
      toolKey,
      stepIdx,
    })
  }

  event(eventName: IAnalyticsEventName, params = {}) {
    this.gaEvent(this.prefix + eventName, this.formatKeys(params))
  }

  /*
   * Helpers
   */
  // eventName and param keys must be underscored
  private gaEvent(eventName: string, params = {}) {
    if (!window.gtag) {
      this.error("gtag is not defined")
      return
    }

    window.gtag("event", eventName, params)
  }

  private formatKeys(params: Record<string, any>) {
    const snakeCased = snakecaseKeys(params)
    return this.prefixKeys(snakeCased)
  }

  private prefixKeys = (params: Record<string, any>) =>
    Object.entries(params).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [this.prefix + key]: value,
      }),
      {},
    )

  private prefix = "br_"
}

const analytics = new Analytics()

export default analytics

/*
 * Typings - Public
 */
// we prefix these with "br_" before reoprting them to easily
// know which events are custom ours and which come with the platform
// e.g. google analytics has "session_start" event etc
export type IAnalyticsEventName =
  | "home_go_to_section"
  | "after_auth_go_to_section"
  | "user_auth_success"
  | "user_auth_fail"
  | "main_purchase_success"
  | "main_purchase_error"
  | "answer_tool_step"
  | "go_back_tool_step"
  | "main_purchase_checkout_button_clicked"

/*
 * Typings - Private
 */
type IScreenName = "home" | "after_auth"

type IAuthProviderName = "google" | "facebook"

interface IAnswerToolStepArgs {
  toolKey: string
  stepIdx: number
  answerType: string
  answerText: string
  goToStepByKey: string
  goToStepByNumber: string
}

interface IGoBackToolStepArgs {
  toolKey: string
  stepIdx: number
}

declare global {
  interface Window {
    gtag: (...args: any[]) => void
  }
}
