import { useState } from "react"
import { Alert, Button, Col, Form, Row } from "react-bootstrap"

import {
  IUserIssue,
  IUserIssueTimeOrientation,
  IUserIssueType,
} from "@nx/api-interfaces"
import { toolBuilder } from "@nx/data-builder"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import s from "./UserIssuesForm.module.scss"

export default function UserIssuesForm({
  timeOrientation,
  type,
  onSubmit,
}: IProps) {
  const buildIssue = () => toolBuilder.buildUserIssue(timeOrientation, type)
  const [issues, setIssues] = useState<IUserIssue[]>([buildIssue()])

  const updateIssue = (issueIndex: number, data: Partial<IUserIssue>) => {
    const nextIssues = [...issues].map((issue, index) =>
      index !== issueIndex ? issue : Object.assign(issue, data),
    )
    setIssues(nextIssues)
  }

  const addEmptyIssue = () => setIssues(issues.concat(buildIssue()))

  const _onSubmit = () => {
    setIssues([buildIssue()])
    onSubmit(issues)
  }

  return (
    <div>
      <div className={s.issues}>
        {issues.map((issue, index) => (
          <Alert key={index}>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="2">
                כותרת
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  value={issue.title}
                  onChange={(evt) =>
                    updateIssue(index, { title: evt.target.value })
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="2">
                עצימות
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  value={issue.intensity}
                  type="number"
                  min={0}
                  max={10}
                  onChange={(evt) =>
                    updateIssue(index, { intensity: Number(evt.target.value) })
                  }
                />
              </Col>
            </Form.Group>
          </Alert>
        ))}
      </div>
      <div className={s.buttons}>
        <Button onClick={addEmptyIssue} variant="secondary">
          להוסיף
        </Button>
        <Button onClick={_onSubmit}>להמשיך</Button>
      </div>
    </div>
  )
}

interface IProps {
  timeOrientation: IUserIssueTimeOrientation
  type: IUserIssueType
  onSubmit: (issues: any) => void
}
