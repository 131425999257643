import { all, put, takeLatest } from "redux-saga/effects"
import { PayloadAction } from "@reduxjs/toolkit"

import { ISsoArgs } from "@nx/api-interfaces"
import { api, uiLogger } from "@nx/ui-utils"

import {
  fetchCurrentUser,
  fetchCurrentUserSuccess,
  fetchCurrentUserError,
  sso,
  ssoSuccess,
  ssoError,
  logout,
  logoutSuccess,
  logoutError,
} from "./current-user.slice"

export default function* currentUserSaga() {
  yield all([
    takeLatest(fetchCurrentUser, fetchCurrentUserSaga),
    takeLatest(sso, ssoSaga),
    takeLatest(logout, logoutSaga),
  ])
}

function* fetchCurrentUserSaga() {
  try {
    const { data: user } = yield api.fetchMe()
    yield put(fetchCurrentUserSuccess(user))
  } catch (error: any) {
    uiLogger.error("[fetchCurrentUserSaga]", error)
    yield put(fetchCurrentUserError())
  }
}

function* ssoSaga(action: PayloadAction<ISsoArgs>) {
  try {
    const { data: user } = yield api.sso(action.payload)
    yield put(ssoSuccess(user))
  } catch (error: any) {
    alert("error, please try again")
    uiLogger.error("[ssoSaga]", error)
    yield put(ssoError())
  }
}

function* logoutSaga() {
  try {
    yield api.logout()
    yield put(logoutSuccess())
  } catch (error: any) {
    alert("error, please try again")
    uiLogger.error("[logoutSaga]", error)
    yield put(logoutError())
  }
}
