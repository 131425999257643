import axios from "axios"
import * as queryString from "query-string"
import { API_BASE_URL } from "@nx/constants"
import { Base } from "@nx/abstract-classes"
import {
  ITool,
  IUser,
  ISsoArgs,
  IToolRecord,
  IToolRecordUserStep,
  IRedirect,
  IUserIssue,
} from "@nx/api-interfaces"

class Api extends Base {
  private qs = null as unknown as IQs

  constructor(opts: IConstructorOptions = {}) {
    super()
    if (opts.qs) {
      this.qs = opts.qs
    }
  }

  /*
   * Tools
   */
  listTools = () => this.get<ITool>("tools")

  createTool = (data: Partial<ITool>) => this.post<ITool>("tools", data)

  updateTool = (id: string, data: ITool) => this.put<ITool>(`tools/${id}`, data)

  getTool = (key: string) => this.get<ITool>(`tools/${key}`)

  cloneTool = (id: string) => this.post<ITool>(`tools/${id}/clone`)

  /*
   * Tool Records
   */
  listToolRecords = () => this.get<IToolRecord[]>("tool-records")

  deleteToolRecords = (ids: string[]) =>
    this.delete<IToolRecord[]>("tool-records", { ids })

  getToolRecord = (id: string) => this.get<IToolRecord>(`tool-records/${id}`)

  createToolRecord = ({
    key: toolKey,
    isRtl,
    steps: toolSteps,
    userSteps,
  }: Partial<ITool & IToolRecord>) =>
    this.post<IToolRecord>("tool-records", {
      toolKey,
      isRtl,
      toolSteps,
      userSteps,
    })

  patchToolRecord = (id: string, { userSteps }: Partial<IToolRecord>) =>
    this.patch<IToolRecord>(`tool-records/${id}`, { userSteps })

  addToolRecordUserStep = (id: string, data: IToolRecordUserStep) =>
    this.post<IToolRecord>(`tool-records/${id}/user-steps`, data)

  /*
   * Current User
   */
  sso = ({ token, provider }: ISsoArgs) =>
    this.post<IUser>("users/auth/" + provider, { token })

  ssoGoogle = (token: string) =>
    this.post<IUser>("users/auth/google", { token })

  ssoFacebook = (token: string) =>
    this.post<IUser>("users/auth/facebook", { token })

  patchMe = (data: Partial<IUser>) => this.patch<IUser>("users/me", data)

  fetchMe = () => this.get<IUser>("users/me")

  logout = () => this.post("users/auth/logout")

  login = () => this.post<IUser>("users/auth/login")

  createUserIssue = (issue: Partial<IUserIssue>) =>
    this.post<IUser>("users/issues", issue)

  /*
   * Redirects
   */
  createRedirect = (data: Partial<IRedirect>) =>
    this.post<IRedirect>("redirects", data)

  /*
   * Builders
   */
  withQs = (qs: IQs) => new Api({ qs })

  /*
   * Helpers
   */
  private get = <T>(path: string) => this.call<T>({ path, method: "get" })

  private post = <T>(path: string, data = {}) =>
    this.call<T>({ path, method: "post", data })

  private put = <T>(path: string, data: Record<string, any>) =>
    this.call<T>({ path, method: "put", data })

  private patch = <T>(path: string, data: Record<string, any>) =>
    this.call<T>({ path, method: "patch", data })

  private delete = <T>(path: string, data: Record<string, any>) =>
    this.call<T>({ path, method: "delete", data })

  private call = <T>({
    path,
    method,
    data = {},
    params = {},
  }: any): Promise<IResponse<T>> =>
    axios({
      method,
      url: this.buildUrl(path),
      data,
      params,
      withCredentials: true,
    })
      .then((resp) => resp.data)
      .then(this.makeLogResponse<T>({ path, method, data }))
      .catch(this.makeLogErrorResponse({ path, method, data }))

  private buildUrl = (path: string) => {
    const fullPath = this.base + path

    if (!this.qs) return fullPath

    const firstChar = fullPath.includes("?") ? "&" : "?"
    this.shout({
      fullPath,
      firstChar,
      qs: this.qs,
      stringified: queryString.stringify(this.qs),
    })
    return fullPath + firstChar + queryString.stringify(this.qs)
  }

  private makeLogResponse =
    <T>({ path, method, data }: any) =>
    (response: IResponse<T>) => {
      this.log({ path, method, data, response })
      return response
    }

  private makeLogErrorResponse =
    ({ path, method, data }: any) =>
    (error: IErrorResponse) => {
      this.error(error, { path, method, data })
      // our API strives to return error.response.data.message
      // we fallback to error.message just in case. Ideally that should
      // never happen
      // @ts-ignore
      const msg = error.response?.data?.message || error.message
      throw new Error(msg)
    }

  private get base() {
    return API_BASE_URL
  }
}

const api = new Api()

export default api

type IResponse<T> = {
  data: T
  meta?: Record<string, any>
}

type IErrorResponse =
  | {
      response: {
        data: {
          message: string
          type: IErrorType
        }
      }
    }
  | {
      message: string
    }

type IErrorType = "unknown"

interface IConstructorOptions {
  qs?: IQs
}

type IQs = Record<string, any>
