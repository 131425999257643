class Events extends EventTarget {
  dispatch(eventName: IEventName, data: any) {
    this.dispatchEvent(new CustomEvent(eventName, { detail: data }))
  }

  listen(eventName: IEventName, cb: any) {
    this.addEventListener(eventName, (evt: any) => {
      cb(evt.detail)
    })
  }

  unlisten(eventName: IEventName, cb: any) {
    this.removeEventListener(eventName, cb)
  }
}

const events = new Events()

export default events

/*
 * Types
 */
type IEventName = "goToLink"
