import capitalize from "lodash/capitalize"
import { Base } from "@nx/abstract-classes"

class StringUtils extends Base {
  /**
   * "foo-bar" -> "Foo bar"
   */
  prettify = (str: string) => capitalize(str.replace(/([A-Z-])/g, " $1"))

  /**
   * "2022-09-08T11:19:00.508Z" -> "08/09/2022 11:19"
   */
  formatDate = (str: string) => {
    const [date, time] = str.split("T")
    const [YYYY, MM, DD] = date.split("-")
    const [hh, mm] = time.split(":")
    return `${DD}.${MM}.${YYYY} ${hh}:${mm}`
  }

  isValidEmail(str: string) {
    return !!str.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
  }
}

const stringUtils = new StringUtils()

export default stringUtils
