import { useEffect, useRef } from "react"

import { analytics, uiLogger } from "@nx/ui-utils"

export default function FacebookAuth({ onSuccess }: IProps) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const triggerAuth = ({ isUserAction }: any) => {
      // @ts-ignore
      window.FB.getLoginStatus(({ authResponse }) => {
        if (!authResponse?.accessToken && isUserAction) {
          uiLogger.log(
            "[FacebookAuth]",
            "auth authResponse missing access token",
          )
          alert("Login failed")
          analytics.userAuthFail("facebook")
        }

        if (!authResponse?.accessToken) return

        analytics.userAuthSuccess("facebook")
        onSuccess(authResponse.accessToken)
      })
    }

    if (!ref.current) {
      uiLogger.log("[FacebookAuth]", "ref is null")
      return
    }

    // @ts-ignore
    window.fbAsyncInit = function () {
      // @ts-ignore
      window.FB.init({
        appId: "424621752939560",
        cookie: true,
        xfbml: true,
        version: "v14.0",
      })

      // @ts-ignore
      window.FB.AppEvents.logPageView()

      triggerAuth({ isUserAction: false })
    }

    const fbSdkScriptId = "facebook-jssdk"
    const fjs = document.getElementsByTagName("script")[0]

    if (!fjs?.parentNode) {
      uiLogger.log("[FacebookAuth]", "fjs parentNode is undefined")
      return
    }

    if (document.getElementById(fbSdkScriptId)) {
      return
    }

    const js = document.createElement("script")
    js.id = fbSdkScriptId
    js.src = "https://connect.facebook.net/en_US/sdk.js"
    fjs.parentNode.insertBefore(js, fjs)

    // @ts-ignore
    window.__onFbLogin = () => triggerAuth({ isUserAction: true })
  }, [onSuccess])

  return (
    <div
      ref={ref}
      className="fb-login-button"
      // 240 is FB minimum
      data-width=""
      data-size="large"
      data-button-type="continue_with"
      data-layout="default"
      data-auto-logout-link="false"
      data-use-continue-as="true"
      data-scope="email,public_profile"
      data-onlogin="__onFbLogin()"
    ></div>
  )
}

interface IProps {
  onSuccess: (token: string) => void
}
