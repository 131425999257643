import axios from "axios"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import { ITool } from "@nx/api-interfaces"
import { API_BASE_URL } from "@nx/constants"

import TopNav from "../../components/TopNav/TopNav"

export default function ToolList() {
  const [areToolsReady, setAreToolsReady] = useState(false)
  const [tools, setTools] = useState<ITool[]>([])

  useEffect(() => {
    const loadTools = async () => {
      const { data } = await axios.get(API_BASE_URL + "tools", {
        withCredentials: true,
      })
      setTools(data.data)
      setAreToolsReady(true)
    }

    loadTools()
  }, [])

  if (!areToolsReady) return <h1>Loading</h1>

  return (
    <div>
      <TopNav />
      {tools.map((tool) => (
        <div key={tool.key} className="tools-list-item">
          <Link to={`/tools/${tool.key}`}>{tool.key}</Link>
        </div>
      ))}
      {tools.length === 0 && <h1>No tools found</h1>}
    </div>
  )
}
