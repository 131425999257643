import { FacebookAuthBtn, GoogleAuthBtn } from "@nx/components"

import { useAppDispatch } from "../../hooks/store"
import { sso } from "../../store/current-user/current-user.slice"
import "./AuthScreen.css"

export default function AuthScreen() {
  const dispatch = useAppDispatch()

  const googleAuth = (token: string) =>
    dispatch(sso({ token, provider: "google" }))

  const fbAuth = (token: string) =>
    dispatch(sso({ token, provider: "facebook" }))

  return (
    <div className="auth-screen">
      <h3>Please log in to continue</h3>
      <GoogleAuthBtn onSuccess={googleAuth} />
      <FacebookAuthBtn onSuccess={fbAuth} />
    </div>
  )
}
