import DOMPurify from "dompurify"
import parse from "html-react-parser"

export default function Html({ children }: IProps) {
  const cleanHTML = DOMPurify.sanitize(children, {
    ALLOWED_TAGS: ["iframe", "p", "span", "ul", "li"],
    // these are attributes of the iframes, might be useful in the future
    // ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"],
  })

  return <div>{parse(cleanHTML)}</div>
}

interface IProps {
  children: string
}
