import { useEffect } from "react"
import cx from "classnames"
import { ThemeProvider } from "react-bootstrap"

import { urlUtils } from "@nx/ui-utils"
import { IUser } from "@nx/api-interfaces"

import { useAppDispatch, useAppSelector } from "../../hooks/store"
import {
  getCurrentTool,
  selectCurrentTool,
  syncStateFromUrl,
} from "../../store/current-tool/current-tool.slice"
import ToolStep from "../ToolStep"
import { setCurrentUser } from "../../store/current-user/current-user.slice"

import "./Root.scss"

export default function Root({ toolKey, user }: IProps) {
  const dispatch = useAppDispatch()
  const tool = useAppSelector(selectCurrentTool)

  useEffect(() => {
    dispatch(getCurrentTool(toolKey))
  }, [dispatch, toolKey])

  useEffect(() => {
    const parsedUrldata = urlUtils.parse()
    dispatch(syncStateFromUrl(parsedUrldata))
  }, [dispatch, toolKey])

  useEffect(() => {
    dispatch(setCurrentUser(user))
  }, [dispatch, user])

  useEffect(() => {
    if (!tool.tool?.isRtl) return

    const id = "rtl-style"
    const existingStyleEl = document.getElementById(id)
    if (existingStyleEl) return

    const styleEl = document.createElement("link")
    styleEl.id = id
    styleEl.setAttribute("rel", "stylesheet")
    styleEl.setAttribute(
      "href",
      "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css",
    )
    styleEl.setAttribute(
      "integrity",
      "sha384-gXt9imSW0VcJVHezoNQsP+TNrjYXoGcrqBZJpry9zJt8PCQjobwmhMGaDHTASo9N",
    )
    styleEl.setAttribute("crossorigin", "anonymous")

    document.head.appendChild(styleEl)
  }, [tool.tool])

  if (tool.isLoading || !tool.tool) return null

  return (
    <ThemeProvider dir={tool.tool.isRtl ? "rtl" : "ltr"}>
      <div
        className={cx({ isRtl: tool.tool?.isRtl, isLtr: !tool.tool?.isRtl })}
      >
        <ToolStep />
      </div>
    </ThemeProvider>
  )
}

interface IProps {
  toolKey: string
  user: IUser
}
