import { Base } from "@nx/abstract-classes"

class EnvUtils extends Base {
  get isDev() {
    return this.env === "development"
  }

  get isTest() {
    return this.env === "test"
  }

  get isProduction() {
    return this.env === "production"
  }

  get env() {
    // @ts-ignore
    return process.env["NODE_ENV"]
  }
}

const envUtils = new EnvUtils()

export default envUtils
