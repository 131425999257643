import isEmpty from "lodash/isEmpty"
import { PayloadAction } from "@reduxjs/toolkit"

import { api, events, uiLogger } from "@nx/ui-utils"
import { IToolAnswer, IUserIssue } from "@nx/api-interfaces"

import {
  submitAnswer,
  submitUserIssue,
} from "../current-tool/current-tool.slice"
import { IStore } from "../init-store"
import { setCurrentUser } from "../current-user/current-user.slice"

const toolsMiddleware: any =
  (store: IStore) => (next: any) => (action: PayloadAction<any>) => {
    if (action.type === submitAnswer.type) {
      onSubmitAnswer(store, action.payload)
    }

    if (action.type === submitUserIssue.type) {
      onSubmitUserIssue(store, action.payload)
    }

    next(action)
  }

export default toolsMiddleware

/*
 * Helpers
 */
async function onSubmitUserIssue(store: IStore, issue: IUserIssue) {
  const { data: user } = await api.createUserIssue(issue)
  store.dispatch(setCurrentUser(user))
}

function onSubmitAnswer(store: IStore, answer: IToolAnswer) {
  if (!isEmpty(answer.updateUserHash)) {
    updateUser(store, answer.updateUserHash)
  }

  if (answer.goToExternalLink) {
    window.location.href = answer.goToExternalLink
  } else if (answer.goToLink) {
    events.dispatch("goToLink", answer.goToLink)
  }
}

async function updateUser(store: IStore, updateUserHash: any) {
  try {
    const { data: user } = await api.patchMe(updateUserHash)
    store.dispatch(setCurrentUser(user))
  } catch (error) {
    uiLogger.errorEnriched("[updateUser]", error)
    alert("update user failed")
  }
}
