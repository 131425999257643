import { all, put, takeLatest } from "redux-saga/effects"

import { api, uiLogger } from "@nx/ui-utils"

import { listTools, listToolsError, listToolsSuccess } from "./tools.slice"

export default function* toolsSaga() {
  yield all([takeLatest(listTools, listToolsSaga)])
}

function* listToolsSaga() {
  try {
    const { data: tools } = yield api.listTools()
    yield put(listToolsSuccess(tools))
  } catch (error: any) {
    uiLogger.error("[listToolsSaga]", error)
    yield put(listToolsError())
  }
}
