import { Base } from "@nx/abstract-classes"

class ObjUtils extends Base {
  /**
   * in production we don't want to space the values since many loggers
   * will treat that as separate logs
   *
   * const spacing = isDev() ? 2 : 0
   * same as calling JSON.stringify(obj, null, spacing)
   */
  stringify = (
    obj: IObj,
    // @ts-ignore
    { spacing = process.env["NODE_ENV"] === "development" ? 2 : 0 } = {},
  ) => JSON.stringify(obj, null, spacing)

  /**
   * Based on Ruby's compact method
   * { a: 0, b: null, c: undefined } -> { a: 0 }
   */
  compact = (obj: IObj) =>
    Object.entries(obj).reduce((acc, [key, value]) => {
      if (![null, undefined].includes(value as any)) {
        acc[key] = value
      }
      return acc
    }, {} as Record<string, any>)
}

const objUtils = new ObjUtils()

export default objUtils

type IObj = Record<string, unknown>
