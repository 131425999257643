import { useState } from "react"
import { Button, Form, FormControl, InputGroup } from "react-bootstrap"

import { IToolAnswer } from "@nx/api-interfaces"

import { useAppDispatch } from "../../hooks/store"
import { submitAnswer } from "../../store/current-tool/current-tool.slice"
import Ltr from "../Ltr/Ltr"
import ToolViewerParsed from "../ToolViewerParsed"

export default function Answer({ answer }: IProps) {
  const dispatch = useAppDispatch()
  const [isOtherActive, setIsOtherActive] = useState(false)
  const [otherValue, setOtherValue] = useState("")

  const submitOther = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault()
    if (!otherValue) return

    dispatch(submitAnswer({ ...answer, text: otherValue }))
  }

  const submit = () => {
    dispatch(submitAnswer(answer))
  }

  const OtherAnswer = () => (
    <div className="answer" data-hook="step-answer">
      {!isOtherActive && (
        <Button
          onClick={() => setIsOtherActive(true)}
          variant="light"
          data-hook="step-answer-btn"
        >
          <Ltr rtl="אחר" ltr="Other" />
        </Button>
      )}
      {isOtherActive && (
        <Form onSubmit={submitOther}>
          <InputGroup className="mb-3">
            <FormControl
              value={otherValue}
              onChange={(evt) => setOtherValue(evt.target.value)}
            />
            <Button
              type="submit"
              variant="outline-secondary"
              data-hook="step-answer-btn"
              disabled={!otherValue}
            >
              <Ltr rtl="להמשיך" ltr="Submit" />
            </Button>
          </InputGroup>
        </Form>
      )}
    </div>
  )

  const _Answer = () => (
    <div className="answer" data-hook="step-answer">
      <Button variant="light" onClick={submit} data-hook="step-answer-btn">
        <ToolViewerParsed>{answer.text}</ToolViewerParsed>
      </Button>
    </div>
  )

  return answer.text ? _Answer() : OtherAnswer()
}

interface IProps {
  answer: IToolAnswer
}
