import { Base } from "@nx/abstract-classes"
import {
  ITool,
  IToolAnswer,
  IUserIssue,
  IToolStep,
  IUserIssueTimeOrientation,
  IUserIssueType,
  IUserIssueIntensityHistory,
  IToolStepIssuesData,
} from "@nx/api-interfaces"

class ToolBuilder extends Base {
  buildTool = ({ ...overrides } = {}): Omit<ITool, "_id"> => ({
    key: "test-tool-key",
    isRtl: false,
    steps: [this.buildStep()],
    version: "0.0.0",
    ...overrides,
  })

  buildUserIssue = (
    timeOrientation: IUserIssueTimeOrientation,
    type: IUserIssueType,
  ): IUserIssue => ({
    title: "",
    intensity: "" as any,
    intensityHistory: [] as IUserIssueIntensityHistory[],
    timeOrientation,
    type,
  })

  buildStep = (overrides = {}): IToolStep => ({
    type: "radio",
    description: "",
    answers: [this.buildAnswer()],
    title: "",
    varKey: "",
    key: "",
    goToStepByKey: "",
    goToStepByNumber: "",
    userIssuesData: {} as IToolStepIssuesData,
    ...overrides,
  })

  buildAnswer = ({
    type = "text" as any,
    ...overrides
  }: Partial<IToolAnswer> = {}): IToolAnswer => ({
    text: "",
    type,
    goToStepByNumber: "",
    goToStepByKey: "",
    isConcern: false,
    goToExternalLink: "",
    goToLink: "",
    updateUserHash: {},
    ...overrides,
  })
}

const toolBuilder = new ToolBuilder()

export default toolBuilder
