import { configureStore } from "@reduxjs/toolkit"

import { loggerMidl } from "@nx/ui-utils"

import sagaMiddleware, { initSagaMiddleware } from "./middlewares/saga"
import rootReducer from "./root-reducer"

export const store = getStore()

export default function initStore() {
  initSagaMiddleware()

  return store
}

/*
 * Utils
 */

// For unit tests
function getStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(sagaMiddleware, loggerMidl), // logger last
  })
}

export type IAppDispatch = typeof store.dispatch
export type IStore = typeof store
