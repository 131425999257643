import TopNav from "../../components/TopNav/TopNav"

export default function Home() {
  return (
    <div>
      <TopNav />
      <h1>Breathe...</h1>
    </div>
  )
}
