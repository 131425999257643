import { Base } from "@nx/abstract-classes"

class RedirectUtils extends Base {
  isCodeValid = (code: string) => {
    if (typeof code !== "string") return false

    if (code.length !== 4) return false

    return true
  }
}

const redirectUtils = new RedirectUtils()

export default redirectUtils
