import { useCallback, useState } from "react"
import { Button, Form, FormControl, InputGroup } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import ReactStars from "react-stars"

import { useAppDispatch, useAppSelector } from "../../hooks/store"
import {
  selectCurrentStep,
  selectCurrentTool,
  stepBack,
  submitAnswer,
  submitStars,
  submitUserIssue,
} from "../../store/current-tool/current-tool.slice"
import ToolViewerParsed from "../ToolViewerParsed"
import Ltr from "../Ltr/Ltr"
import UserIssuesForm from "../UserIssuesForm/UserIssuesForm"

import Answer from "./Answer"

import "./ToolStep.css"

export default function ToolStep() {
  const dispatch = useAppDispatch()
  const currentTool = useAppSelector(selectCurrentTool)
  const step = useAppSelector(selectCurrentStep)

  const [inputValue, setInputValue] = useState("")
  const [textareaValue, setTextareaValue] = useState("")

  const submitTextarea = useCallback(() => {
    dispatch(submitAnswer({ text: textareaValue, type: "input" } as any))
    setTextareaValue("")
  }, [dispatch, textareaValue])

  const handleSubmitOnAltEnter = (evt: any) => {
    if (evt.keyCode !== 13 || !(evt.altKey || evt.metaKey)) return

    submitTextarea()
  }

  const Title = () =>
    step.title && (
      <h1 data-hook="step-title">
        <ToolViewerParsed>{step.title}</ToolViewerParsed>
      </h1>
    )

  const Description = () => (
    <div data-hook="step-description">
      <ToolViewerParsed>{step.description}</ToolViewerParsed>
    </div>
  )

  const Answers = () => (
    <div className="answers">
      {["radio", "checkbox"].includes(step.type) &&
        step.answers?.map((a, idx) => <Answer answer={a} key={idx} />)}
    </div>
  )

  const submitInput = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault()
    dispatch(submitAnswer({ text: inputValue, type: "input" } as any))
    setInputValue("")
  }

  const Input = () =>
    step.type === "input" && (
      <Form onSubmit={submitInput} data-hook="step-input">
        <InputGroup className="mb-3">
          <FormControl
            value={inputValue}
            onChange={(evt) => setInputValue(evt.target.value)}
          />
          <Button
            type="submit"
            disabled={!inputValue}
            variant="primary"
            data-hook="step-answer-btn"
          >
            <Ltr ltr="Continue" rtl="להמשיך" />
          </Button>
        </InputGroup>
      </Form>
    )

  const Textarea = () =>
    step.type === "textarea" && (
      <div>
        <Form.Control
          value={textareaValue}
          onChange={(evt) => setTextareaValue(evt.target.value)}
          data-hook="step-textarea"
          className="textarea"
          as="textarea"
          onKeyDown={handleSubmitOnAltEnter}
          rows={3}
        />
        <Button
          variant="primary"
          onClick={submitTextarea}
          disabled={!textareaValue}
          data-hook="step-answer-btn"
        >
          <Ltr ltr="Continue" rtl="להמשיך" />
        </Button>
      </div>
    )

  const _submitStars = (stars: number) => dispatch(submitStars(stars))

  const Stars = () =>
    step.type === "stars" && (
      <div data-hook="stars" className="tool-step-stars">
        <ReactStars onChange={_submitStars} size={50} />
      </div>
    )

  const _submitUserIssue = (issues: any[]) => dispatch(submitUserIssue(issues))

  const IssuesToWorkOn = () =>
    step.type === "userIssues" && (
      <UserIssuesForm
        timeOrientation={step.userIssuesData.timeOrientation}
        type={step.userIssuesData.type}
        onSubmit={_submitUserIssue}
      />
    )

  const BackBtn = () =>
    currentTool.currentStepNumber.history.length > 0 && (
      <Button
        data-hook="back-btn"
        variant="outline"
        className="back-btn"
        onClick={() => dispatch(stepBack())}
      >
        <FontAwesomeIcon
          className="back-btn-icon"
          icon={faArrowLeft}
        ></FontAwesomeIcon>
        <Ltr ltr="Back" rtl="אחורה" />
      </Button>
    )

  return (
    <div>
      <div className="tool-step-inner">
        {Title()}
        {Description()}
        {Answers()}
        {Input()}
        {Textarea()}
        {Stars()}
        {IssuesToWorkOn()}
      </div>
      <hr />
      {BackBtn()}
    </div>
  )
}
