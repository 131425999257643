import { createLogger } from "redux-logger"
import { envUtils } from "@nx/utils"

import { shouldSupressLog } from "../../ui-utils"

const loggerMiddleware = createLogger({
  // predicate: () => !shouldSupressLog(), // uncomment for tests
  predicate: () => !shouldSupressLog() && !envUtils.isTest,
})

export default loggerMiddleware
